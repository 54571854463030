<template>
  <b-container style="margin: 0px !important;">
    <b-row
      align-h="center"
      class="w-100"
    >
      <form-render-view
        :current-form-name="currentForm"
        :pre-populate="appointmentData"
        :selected-doctype="'Patient'"
        :selected-doctype-reference="this.appointmentData.patient"
        :is-save-only="true"
        :is-readonly="true"
        :power-through="true"
        @callback="callback"
      />
    </b-row>
  </b-container>
</template>

<script>
import FormRenderView from '@/views/components/formbuilder/Frm.vue'
import {
  savePrePayment,
} from './availability.js'
import ReceivePayment from './ReceivePayment.vue'

export default {
  name: 'AppointmentForm',
  components: { FormRenderView, ReceivePayment },

  props: {
    appointmentData: { type: String, required: false },
  },
  data() {
    return {
      currentForm: 'Appointment Form',
      patient: null,
      customerNumber: null,
      patientData: null,
      patientName: null,
      cleared: false,
    }
  },
  methods: {
    callback() {
      this.cleared = true
      // this.$router.push({ name: 'fill-form', params: { } })
    },
    show() {

    },
    getPatientData() {
      const parent = this
      this.createPaymentEntry()
      this.show_alert('Fetching payment details')
      frappe.db.get_doc('Patient', this.appointmentData.patient).then(results => {
        parent.patientData = results
        parent.patient = results.patient
        parent.customerNumber = results.customer
        parent.patientName = results.patient_name
        this.patient = this.appointmentData.patient
        this.createPaymentEntry()
      })
    },
    createPaymentEntry() {
      const parent = this
      this.currentDialogData = {
        title: `Add Payment Entry for ${parent.patientName}`,
        fields: [
          {
            label: 'Table Container',
            fieldname: 'table_container',
            fieldtype: 'HTML',
            default: '',
            readonly: 0,
          },
          {
            label: 'Client Name',
            fieldname: 'party',
            fieldtype: 'Read Only',
            options: 'Customer',
            default: `${parent.patientName} (${parent.customerNumber})`,
            readonly: 0,
          },
          {
            label: 'Mode of Payment',
            fieldname: 'mode_of_payment',
            fieldtype: 'Link',
            options: 'Mode of Payment',

          },
          {
            label: 'Reference',
            fieldname: 'reference_no',
            fieldtype: 'Data',

            // default: Math.floor(Date.now()).toString(36).toUpperCase().toString()
          },
          {
            label: 'Amount',
            fieldname: 'paid_amount',
            fieldtype: 'Currency',
          },
          {
            label: 'Confirm Amount',
            fieldname: 'confirm_amount',
            fieldtype: 'Currency',
            change() {
              // Update "Amount Tendered" when "Confirm Amount" changes
              const confirmAmount = this.get_value()
              dialog.set_value('tendered_amount', confirmAmount)
            },
            onfocusout() {
              // Additional event (onfocusout) to update "Amount Tendered"
              const confirmAmount = this.get_value()
              dialog.set_value('tendered_amount', confirmAmount)
            },
          },
          {
            label: 'Amount Tendered',
            fieldname: 'tendered_amount',
            fieldtype: 'Currency',
            readonly: 1,
            disabled: 1,
            //  reqd: 0
          },
        ],
        primary_action_label: 'Submit',
        primary_action(values) {
          if (true) {
            savePrePayment({
              customer_name: parent.customerNumber,
              patient_name: parent.patient,
              amount: values.paid_amount,
              // account_for_change:values.account_for_change,
              change_amount: values.change_amount,
              amount_tendered: values.tendered_amount,
              reference_no: values.reference_no,
              // paid_to: values.paid_to
              mode_of_payment: values.mode_of_payment,
            }).then(r => {
              parent.$emit('newPaymentEntry')
              this.show_alert({
                message: 'Payment Entry Created',
                indicator: 'green',
              }, 5)
              dialog.hide()
            })
          }
        },
        secondary_action_label: 'Cancel',
        secondary_action() {
          dialog.hide()
        },
      }
      dialog.fields_dict.tendered_amount.df.onchange = () => {
        if (dialog.get_value('paid_amount') === dialog.get_value('confirm_amount')) {
          if (dialog.get_value('tendered_amount') >= dialog.get_value('paid_amount')) {
            dialog.set_values({ change_amount: dialog.get_value('tendered_amount') - dialog.get_value('paid_amount') })
          } else {
            const diff = dialog.get_value('tendered_amount') - dialog.get_value('paid_amount')
            this.msgprint(`Sorry the tendered amount is less than paid amount by ${diff.toFixed(2)}`)
          }
        } else {

        }
      }

      this.openDialog(this.currentDialogData)
    },
  },
}
</script>

  <style></style>
