<template>
  <wrapper class="w-100">
    <b-container class="w-100">
      <b-row
        class="w-100"
        align-h="between"
      >
      <div>  <b-form-radio-group
          v-model="selected"
          :options="options"
          :aria-describedby="ariaDescribedby"
          button-variant="outline-secondary"
          name="radio-btn-outline"
          buttons
          size="sm"
        /></div>
        <!-- <b-button variant="primary">GET payement</b-button> -->
        <receive-payment
          v-if="patientName"
          class="mr-1 mb-1"
          :is-button="true"
          :patient-number="patientName"
        />
      </b-row>
      <b-row class="mt-2">
        <component
          :is="selected"
          :service-unit="serviceUnit"
          :appointment-data="appointmentData"
          @book="setBookData"
          @serviceUnit="setServiceUnit"
          @passDate="passDate"
        />
      </b-row>
      <b-row class="mb-6" />
    </b-container>
  </wrapper>
</template>

<script>
import AppointmentForm from './AppointmentForm.vue'
import SelectPractitioner from './SelectPractitioner.vue'
import ReceivePayment from './ReceivePayment.vue'

export default {
  name: 'NewAppointment',
  components: { SelectPractitioner, AppointmentForm, ReceivePayment },
  props: {
    patientFromChart: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      appointmentTime: null,
      appointmentDate: null,
      serviceUnit: null,
      practitioner: null,
      department: null,
      patientName: null,
      appointmentDuration: null,
      selected: 'SelectPractitioner',
      options: [
        { text: 'Availability', value: 'SelectPractitioner' },
        { text: 'Book', value: 'AppointmentForm' },
      ],
      appointmentData: null,
    }
  },
  created() {
    if (this.patientFromChart !== null) {
      this.patientName = this.patientFromChart
    } else {
      this.patientName = this.$router.currentRoute.params.id

      if (this.$router.currentRoute.params.patient) {
        this.patientName = this.$router.currentRoute.params.patient
        this.appointmentName = this.$router.currentRoute.params.appointment
      }
    }
  },

  mounted() {
      
    
    if (this.patientFromChart !== null) {
      this.patientName = this.patientFromChart
    } else {
      this.setTitle("Book Appointment")
      this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
      this.patientName = this.$router.currentRoute.params.id

      if (this.$router.currentRoute.params.patient) {
        this.patientName = this.$router.currentRoute.params.patient
        this.appointmentName = this.$router.currentRoute.params.appointment
      
      }
      
    }
  },
  methods: {
    passDate(selectedDate) {
      this.$emit('passselectedDate', selectedDate)
    },
    setServiceUnit(unit) {
      this.serviceUnit = unit
    },
    setBookData(val) {
      this.appointmentData = val
      this.appointmentData.patient = this.patientName
      this.appointmentData.name = this.appointmentName
      this.appointmentData.appointment_type = val.appointment_type
      this.selected = 'AppointmentForm'
    },
    open() {
      this.check_and_set_availability({})
    },
    check_and_set_availability(frm) {
      let selected_slot = null
      let service_unit = null
      let duration = null
      frm.doc = {}
      const me = this

      show_availability()

      function show_empty_state(practitioner, appointment_date) {
        this.msgprint({
          title: ('Not Available'),
          message: ('Healthcare Practitioner {0} not available on {1}', [
            practitioner.bold(),
            appointment_date.bold(),
          ]),
          indicator: 'red',
        })
      }

      function show_availability() {
        let selected_practitioner = ''
        const d = new frappe.ui.Dialog({
          title: ('Available slots'),
          fields: [
            {
              fieldtype: 'Link',
              options: 'Medical Department',
              reqd: 1,
              fieldname: 'department',
              label: 'Medical Department',
            },
            { fieldtype: 'Column Break' },
            {
              fieldtype: 'Link',
              options: 'Healthcare Practitioner',
              reqd: 1,
              fieldname: 'practitioner',
              label: 'Healthcare Practitioner',
            },
            { fieldtype: 'Column Break' },
            {
              fieldtype: 'Date',
              reqd: 1,
              fieldname: 'appointment_date',
              label: 'Date',
            },
            { fieldtype: 'Section Break' },
            { fieldtype: 'HTML', fieldname: 'available_slots' },
          ],
          primary_action_label: ('Book'),
          primary_action() {
            me.appointmentTime = selected_slot
            me.appointmentDuration = duration
            me.appointmentDate = d.get_value('appointment_date')
            me.practitioner = d.get_value('practitioner')
            me.department = d.get_value('department')

            const data = {
              appointmentTime: me.appointmentTime,
              appointmentDate: me.appointmentDate,
              practitioner: me.practitioner,
              department: me.department,
              appointmentDuration: me.appointmentDuration,
            }
            alert(JSON.stringify(data))

            // frm.set_value('appointment_time', selected_slot);
            // if (!frm.doc.duration) {
            // 	frm.set_value('duration', duration);
            // }
            // frm.set_value('practitioner', d.get_value('practitioner'));
            // frm.set_value('department', d.get_value('department'));
            // frm.set_value('appointment_date',);
            // if (service_unit) {
            // 	frm.set_value('service_unit', service_unit);
            // }
            d.hide()
            // frm.enable_save();
            // frm.save();
            d.get_primary_btn().attr('disabled', true)
          },
        })

        d.set_values({
          department: frm.doc.department,
          practitioner: frm.doc.practitioner,
          appointment_date: frm.doc.appointment_date,
        })

        d.fields_dict.department.df.onchange = () => {
          d.set_values({
            practitioner: '',
          })
          const department = d.get_value('department')
          if (department) {
            d.fields_dict.practitioner.get_query = function () {
              return {
                filters: {
                  department,
                },
              }
            }
          }
        }

        // disable dialog action initially
        d.get_primary_btn().attr('disabled', true)

        // Field Change Handler

        const fd = d.fields_dict

        d.fields_dict.appointment_date.df.onchange = () => {
          show_slots(d, fd)
        }
        d.fields_dict.practitioner.df.onchange = () => {
          if (
            d.get_value('practitioner')
            && d.get_value('practitioner') != selected_practitioner
          ) {
            selected_practitioner = d.get_value('practitioner')
            show_slots(d, fd)
          }
        }
        d.show()
      }

      function show_slots(d, fd) {
        if (d.get_value('appointment_date') && d.get_value('practitioner')) {
          fd.available_slots.html('')
          this.api({
            method:
              'erpnext.healthcare.doctype.patient_appointment.patient_appointment.get_availability_data',
            args: {
              practitioner: d.get_value('practitioner'),
              date: d.get_value('appointment_date'),
            },
            callback: r => {
              const data = r.message
              if (data.slot_details.length > 0) {
                const { $wrapper } = d.fields_dict.available_slots

                // make buttons for each slot
                const { slot_details } = data
                let slot_html = ''
                for (let i = 0; i < slot_details.length; i++) {
                  slot_html += `<label>${slot_details[i].slot_name}</label>`
                  slot_html = `${slot_html
                  }<br/>${
                    slot_details[i].avail_slot
                      .map(slot => {
                        let disabled = ''
                        const start_str = slot.from_time
                        const slot_start_time = moment(
                          slot.from_time,
                          'HH:mm:ss',
                        )
                        const slot_to_time = moment(slot.to_time, 'HH:mm:ss')
                        const interval = ((slot_to_time - slot_start_time) / 60000) | 0
                        // iterate in all booked appointments, update the start time and duration
                        slot_details[i].appointments.forEach(booked => {
                          const booked_moment = moment(
                            booked.appointment_time,
                            'HH:mm:ss',
                          )
                          const end_time = booked_moment
                            .clone()
                            .add(booked.duration, 'minutes')
                          // Deal with 0 duration appointments
                          if (
                            booked_moment.isSame(slot_start_time)
                            || booked_moment.isBetween(
                              slot_start_time,
                              slot_to_time,
                            )
                          ) {
                            if (booked.duration == 0) {
                              disabled = 'disabled="disabled"'
                              return false
                            }
                          }
                          // Check for overlaps considering appointment duration
                          if (
                            slot_start_time.isBefore(end_time)
                            && slot_to_time.isAfter(booked_moment)
                          ) {
                            // There is an overlap
                            disabled = 'disabled="disabled"'
                            return false
                          }
                        })
                        return `<button class="btn btn-default"
                                      data-name=${start_str}
                                      data-duration=${interval}
                                      data-service-unit="${slot_details[i].service_unit || ''}"
                                      style="margin: 0 10px 10px 0; width: 72px;" ${disabled}>
                                      ${start_str.substring(0, start_str.length - 3)}
                                  </button>`
                      })
                      .join('')}`
                  slot_html += '<br/>'
                }

                $wrapper
                  .css('margin-bottom', 0)
                  .addClass('text-center')
                  .html(slot_html)

                // blue button when clicked
                $wrapper.on('click', 'button', function () {
                  const $btn = $(this)
                  $wrapper.find('button').removeClass('btn-primary')
                  $btn.addClass('btn-primary')
                  selected_slot = $btn.attr('data-name')
                  service_unit = $btn.attr('data-service-unit')
                  duration = $btn.attr('data-duration')
                  // enable dialog action
                  d.get_primary_btn().attr('disabled', null)
                })
              } else {
                //	fd.available_slots.html('Please select a valid date.'.bold())
                show_empty_state(
                  d.get_value('practitioner'),
                  d.get_value('appointment_date'),
                )
              }
            },
            freeze: true,
            freeze_message: ('Fetching records......'),
          })
        } else {
          fd.available_slots.html(
            (
              'Appointment date and Healthcare Practitioner are Mandatory'
            ).bold(),
          )
        }
      }
    },
  },
}
</script>

<style>
#btn-radios-2 {
  background: white !important;
}
</style>
