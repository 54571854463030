<template>
  <b-container>
    <b-card>
      <b-row
        class="w-100 mb-1"
        h-align="start"
      >
        <b-col
          sm="12"
          md="4"
          class="mb-1"
        >
          <b-form-radio-group
            id="radio-group-2"
            v-model="appointmentType"
            :aria-describedby="ariaDescribedby"
          >
            <b-form-radio value="Cash Payer">
              Cash Payer
            </b-form-radio>
            <b-form-radio value="NHIF/Corporate">
              Insurance
            </b-form-radio>
          </b-form-radio-group>
        </b-col>

        <b-col
          sm="12"
          md="4"
          class="mb-1"
        >
          <div class="parent">
            <!-- <div ref="doctype" class="ref-field-input" /> -->
            <doctype-link
              :doctype="'Healthcare Service Unit'"
              :filters="{ allow_appointments: 1, disabled: 0 }"
              :placeholder="'Healthcare Service Unit'"
              @selected="makeSelectDoctypeControl"
            />
          </div>
        </b-col>
        <b-col
          sm="12"
          md="4"
        >
          <b-form-datepicker
            v-model="selectedDate"
            class="w-100"
            :show-decade-nav="true"
            today-button
            reset-button
            close-button
            locale="en"
          />
        </b-col>
      </b-row>
      <b-button
        v-if="false"
        style="opacity:0;"
        variant="light"
        size="sm"
        @click="clearCachedPractitioners()"
      > <i
        class="fa fa-trash"
      /></b-button>
      <b-row class="w-100">

        <b-col
          sm="12"
          md="4"
          class="mb-1"
        >
          <doctype-link
            :doctype="'Healthcare Practitioner'"
            :filters="{ name: ['IN', service_unit_practitioners] }"
            :placeholder="'Previously selected doctor'"
            @selected="makeCachedDoctorDoctypeControl"
          />

        </b-col>
        <b-col
          v-if="false"
          sm="12"
          md="4"
          class="mb-1"
        >
          <doctype-link
            :doctype="'Healthcare Practitioner'"
            :api="doctorCustomSearchApi"
            @selected="makeSelectUpdatedDoctorDoctypeControl"
          />
        </b-col>
        <b-col
          sm="12"
          md="4"
          class="mb-1"
        >
          <!--  <div ref="doctordoctype" class="ref-field-input" />  -->
          <doctype-link
            :doctype="'Healthcare Practitioner'"
            :filters="[selectedServiceUnit]"
            :api="'clinical.api.appointment.appointment.get_doctors_in_service_unit_filter_params'"
            :placeholder="'Search doctor'"
            @selected="makeSelectDoctorDoctypeControl"
          />
        </b-col>

        <b-col
          sm="12"
          md="4"
          class="mb-1"
        >

          <doctype-link
            :doctype="'Patient'"
            :placeholder="$router.currentRoute.params.patient || 'Search Patient'"
            @selected="selectPatient"
          />
        </b-col>
        <b-col
          sm="12"
          md="12"
          class="mb-1"
        >
          <b-row
            class="w-100"
            h-align="end"
          >
            <b-col />
            <b-button
              variant="primary"
              size="sm"
              @click="ccheckSlotsAvailablePractitioners()"
            >
              Proceed</b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-row class=" availability">
      <b-col
        v-for="item in availabilities"
        :key="item"
        cols="12"
        class="mb-2"
      >
        <b-card
          v-if="item.slots.length"
          class="text-center"
        >
          <b-row>
            <b-col
              v-for="slot in item.slots"
              :key="slot"
              cols="12"
            >
              <b-col
                v-if="slot.service_unit == selectedServiceUnit"
                cols="12"
              >
                <h5 class="pull-left mr-4">
                  {{ item.doctor.practitioner_name }} [{{
                    item.doctor.department
                  }}
                  ]
                </h5>

                <b-progress
                  class="mt-2 ml-3"
                  :max="slot.avail_slot.length"
                  show-value
                >
                  <b-progress-bar
                    :value="slot.avail_slot.filter((aval) =>
                      checkAvailableSlot(slot.appointments, aval)
                    ).length
                    "
                    :variant="getColor(
                      (slot.avail_slot.filter((aval) =>
                        checkAvailableSlot(slot.appointments, aval)
                      ).length) /
                        slot.avail_slot.length
                    )
                    "
                  />
                </b-progress>
              </b-col>
              <b-row
                v-if="slot.service_unit == selectedServiceUnit"
                style="width:100%"
              >
                <b-col>
                  <div
                    class="text-center mx-auto  my-1"
                    style="width:100%"
                  >
                    <b-button
                      variant="primary"
                      class="mx-auto"
                      style="width:100%"
                    >
                      {{ slot.slot_name }}

                      <b-badge variant="light">{{
                        slot.avail_slot.length
                      }}</b-badge>
                    </b-button>
                  </div>
                </b-col>
                <b-col cols="12">
                  <span
                    v-for="aval in slot.avail_slot"
                    :key="aval"
                  >
                    <b-button
                      v-if="checkAvailableSlot(slot.appointments, aval)"
                      style="width: 85px; margin:1.5px"
                      variant="outline-primary"
                      @click="
                        book(
                          item.doctor.name,
                          selectedDate,
                          aval.from_time,
                          selectedServiceUnit,
                          appointmentType,
                          null
                        )
                      "
                    >{{
                      `${aval.from_time.split(":")[0]}:${aval.from_time.split(":")[1]
                      }`
                    }}
                    </b-button>

                    <b-button
                      v-else
                      style="width: 75px;"
                      class="m-1"
                      variant="primary"
                    >{{
                      `${aval.from_time.split(":")[0]}:${aval.from_time.split(":")[1]
                      }`
                    }}
                    </b-button>
                  </span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import {
  getGetAvailabilities, getServiceUnitPractitioners, checkSlotsAvailablePractitioners, checkTurnedUpBookings,
} from './availability.js'

export default {
  name: 'SelectPractitioner',
  props: {
    serviceUnit: { type: String, required: false },
  },
  data() {
    return {
      selectedServiceUnit: null,
      selectedDoctor: null,
      updatedSelectedDoctor: null,
      selectedDate: new Date().toISOString().slice(0, 10),
      availabilities: [],
      value: 45,
      max: 100,
      show_slots: 0,
      loader: true,
      service_unit_practitioners: [],
      appointmentType: '',
      maxBookings: 0,
      allowOnDayBookings: 0,
      doctorCustomSearchApi: 'clinical.api.appointment.appointment.get_service_unit_practitioners',
    }
  },
  watch: {
    selectedServiceUnit(val) {
      // this.fetchAvailabilities();
      localStorage.setItem('cachedServiceUnit', val)
      this.getServiceUnitPractitioners(val)
    },
    selectedDate(val) {
      console.log('Date changed')
      this.fetchAvailabilities()
      this.$emit('passDate', val)
    },
    updatedSelectedDoctor(val) {
      // get persisted list and update
      const existingList = localStorage.getItem('cachedPractitioners')
      if (existingList && val != null) {
        const actualList = JSON.parse(existingList)
        if (!actualList.includes(val)) {
          actualList.push(val)
          localStorage.setItem('cachedPractitioners', JSON.stringify(actualList))
        }
      } else if (val != null) {
        localStorage.setItem('cachedPractitioners', JSON.stringify([val]))
      }
      this.fetchAvailabilities()
    },
  },

  mounted() {
    // set practitioners
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
    if (localStorage.getItem('cachedPractitioners')) {
      this.service_unit_practitioners = JSON.parse(localStorage.getItem('cachedPractitioners'))
    }

    // set service unit
    if (localStorage.getItem('cachedServiceUnit')) {
      this.selectedServiceUnit = localStorage.getItem('cachedServiceUnit')
    }

    if (this.serviceUnit) {
      this.selectedServiceUnit = this.serviceUnit
    }
  },
  methods: {
    selectPatient(patient) {
      this.$router.push({ name: 'book-appointment-patient', params: { patient } })
    },
    clearCachedPractitioners() {
      localStorage.setItem('cachedPractitioners', [])
      localStorage.setItem('cachedServiceUnit', '')
    },
    displayAlert() {
      this.msgprint({
        title: "Manual slot selection has been deactivated. Under Facility Settings, check 'Show Appointment Time Slots' to activate it.",
        message: 'To book appointment, please click on the `Proceed` button on the top right corner of the screen',
        indicator: 'orange',
      })
    },
    getColor(percentage) {
      percentage *= 100
      if (percentage > 80) {
        return 'success'
      } if (percentage > 40 && percentage < 79) {
        return 'warning'
      }
      return 'danger'
    },
    checkAvailableSlot(appointments, slot) {
      const val = appointments.find(
        item => item.appointment_time === slot.from_time,
      )
      if (!val) {
        return true
      }
      return false
    },
    getFirstEmptySlot(doctor) {
      console.log('Doctor', doctor)
      console.log('Availabilities', this.availabilities)
      const doctorsContext = this.availabilities.find(item => item.doctor.name == doctor)
      console.log('Doctors Context ', doctorsContext)
      const slotsForCurrentServiceUnit = doctorsContext.slots.find(slot => slot.service_unit == this.selectedServiceUnit)
      if (slotsForCurrentServiceUnit == null || slotsForCurrentServiceUnit == undefined) {
        this.msgprint({
          title: 'There are no availabilities set for the selected practitioner',
          message: 'Please ensure that practitioner schedule has been well set ',
          indicator: 'red',
        })
      }
      const availableSlots = slotsForCurrentServiceUnit.avail_slot
      console.log('Available Slots  ', availableSlots)
      const availableAppointments = doctorsContext.slots[0].appointments
      console.log('Available Apps  ', availableAppointments)
      const slotsTimeList = []
      availableSlots.forEach(element => {
        slotsTimeList.push(element.from_time)
      })
      console.log('Slots Time  ', slotsTimeList)
      const appointmentTimeList = []
      availableAppointments.forEach(element => {
        appointmentTimeList.push(element.appointment_time)
      })
      console.log('Av apps  ', availableAppointments)
      const emptySlots = slotsTimeList.filter(item => !appointmentTimeList.includes(item))
      console.log('Empty Slots  ', emptySlots)
      // get emptyslot which is greater than now
      const currentDate = new Date()
      const firstEmptyFromNow = emptySlots.filter(item => {
        const dateString = `${this.selectedDate} ${item}`
        const selectedDateTime = new Date(dateString)
        return selectedDateTime >= currentDate
      })

      console.log('Free Slots ', firstEmptyFromNow)

      if (firstEmptyFromNow.length == 0) {
        if ((this.selectedDate == this.formatDate()) && (this.allowOnDayBookings == 1)) {
          console.log('Up', 'Same Day')
          checkTurnedUpBookings(this.selectedServiceUnit, this.selectedDate).then(res => {
            if (res == 1) {
              this.autoBook(doctor, firstEmptyFromNow[0])
            } else {
              this.msgprint({
                title: 'Maximum number of bookings set for this service unit has been reached!',
                message: '',
                indicator: 'red',
              })
            }
          })
        } else {
          this.msgprint({
            title: 'There are no further empty slot for the selected Doctor',
            message: 'Limit reached for today!. Please book patients to the next appropriate day ',
            indicator: 'red',
          })
        }
      } else {
        this.autoBook(doctor, firstEmptyFromNow[0])
      }
    },
    setServiceUnit(unit) {
      this.$emit('serviceUnit', unit)
    },
    makeSelectDoctypeControl(value) {
      this.selectedServiceUnit = value
      this.setServiceUnit(value)
    },
    makeSelectDoctorDoctypeControl(value) {
      this.selectedDoctor = value
      this.updatedSelectedDoctor = value
      this.getFirstEmptySlot(value)
    },
    makeSelectUpdatedDoctorDoctypeControl(value) {
      this.updatedSelectedDoctor = value
    },
    oldFetchAvailabilities() {
      if (this.selectedServiceUnit && this.selectedDate) {
        getGetAvailabilities(this.selectedServiceUnit, this.selectedDate).then(
          result => {
            if (result.show_slots == 1) {
              this.show_slots = 1
            }
            this.availabilities = result.availabilities
          },
        )
      }
    },
    fetchAvailabilities() {
      if (this.updatedSelectedDoctor && this.selectedServiceUnit && this.selectedDate) {
        getGetAvailabilities(this.selectedServiceUnit, this.selectedDate, this.updatedSelectedDoctor).then(
          result => {
            result = result.message
            console.log('REsults', result)
            if (result.show_slots == 1) {
              this.show_slots = 1
            }
            this.availabilities = result.availabilities
            this.allowOnDayBookings = result.allow_on_day_bookings
            this.maxBookings = result.max_number_of_bookings
            console.log('Fetched Availabs', result.availabilities)
          },
        )
      }
    },
    // getServiceUnitPractitioners(){
    //   if(this.selectedServiceUnit){
    //      getServiceUnitPractitioners(this.selectedServiceUnit).then(
    //       (result) => {
    //         let extract = result.map(x=>x.name)
    //         this.service_unit_practitioners = extract
    //         // this.makeSelectUpdatedDoctorDoctypeControl();

    //       }
    //     );
    //   }
    // },
    ccheckSlotsAvailablePractitioners() {
      if (this.updatedSelectedDoctor && this.selectedServiceUnit && this.selectedDate) {
        checkSlotsAvailablePractitioners(this.selectedServiceUnit, this.selectedDate, this.updatedSelectedDoctor).then(
          result => {
            const xy = this.selectedDate + this.selectedServiceUnit
            console.log('Resolts', xy)
            if (result) {
              this.getFirstEmptySlot(this.updatedSelectedDoctor)
            } else {
              alert('There seems to be no empty slot for the selected Doctor')
            }
          },
        )
      }
    },
    autoBook(practitioner, time) {
      this.book(practitioner, this.selectedDate, time, this.selectedServiceUnit, this.appointmentType, 'autobook')
    },
    book(practitioner, appointment_date, appointment_time, service_unit, appointment_type, means) {
      if (appointment_type == '') {
        this.msgprint({
          title: 'Please select the type of appointment',
          message: 'Select either Cash Payer or NHIF/Corporate',
          indicator: 'orange',
        })
      } else if (this.show_slots == 1 || (this.show_slots == 0 && means == 'autobook')) {
        this.$emit('book', {
          practitioner,
          appointment_date,
          appointment_time,
          service_unit,
          appointment_type,
        })
      } else {
        this.displayAlert()
      }
    },
    formatDate() {
      const d = new Date()
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()

      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join('-')
    },
  },
}
</script>

<style scoped>
.availability {
  margin-right: 2px;
  margin-left: 2px;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding-left: 0px !important;
}
</style>
